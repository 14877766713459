// Copyright Epic Games, Inc. All Rights Reserved.
import React, { useEffect, useRef, useState } from "react";
import {
  Config,
  AllSettings,
  PixelStreaming,
} from "@epicgames-ps/lib-pixelstreamingfrontend-ue5.2";
import "../App.css";
import LogoutIcon from "@mui/icons-material/Logout";
import { closePixelStreamInstance } from "../APIHandler/ApiHandler";
import { useNavigate } from "react-router-dom";

interface ProcessIds {
  serverProcessId?: number;
  playerProcessId?: number;
  httpsPort?: number;
  streamerPort?: number;
  httpPort?: number;
  userID?: string;
  sessionID?: string;
}

export interface PixelStreamingWrapperProps {
  initialSettings?: Partial<AllSettings>;
  selectedStream: Function;
  processIds: ProcessIds;
  stellaReaction: Boolean;
}

export const PixelStreamingWrapper = ({
  initialSettings,
  selectedStream,
  processIds,
  stellaReaction,
}: PixelStreamingWrapperProps) => {
  const videoParent = useRef<HTMLDivElement>(null);
  const [pixelStreaming, setPixelStreaming] = useState<PixelStreaming>();
  const [character, setCharacter] = useState(-1);
  const [clickToPlayVisible, setClickToPlayVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoParent.current) {
      const config = new Config({ initialSettings });
      const streaming = new PixelStreaming(config, {
        videoElementParent: videoParent.current,
      });

      console.log(streaming);
      streaming.addEventListener("playStreamRejected", () => {
        setClickToPlayVisible(true);
      });
      streaming.addResponseEventListener(
        "handle_responses",
        (response: string) => {
          // if (response.includes("StreamActive")) return;
          console.log(response);
          handleUnrealResponse(response, streaming); // Pass streaming as an argument
        }
      );
      setPixelStreaming(streaming);
      streaming.play();
      setClickToPlayVisible(true);
      // Cleanup function
      return () => {
        streaming.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (stellaReaction) {
      console.log("Stella Reaction On");

      handleUnrealResponse("StellaReactionOn", pixelStreaming); // Pass streaming as an argument
    }
  }, [stellaReaction]);

  const handleUnrealResponse = (
    response: string,
    stream: PixelStreaming | undefined
  ) => {
    switch (response) {
      case "StellaChatOn":
        console.log("StellaChatOn");

        selectedStream({ speak: false });
        break;
      case "StellaChatClose":
        console.log("StellaChatClose");
        selectedStream({ speak: true });
        break;
      case "StellaReactionOn":
        stream?.emitUIInteraction(
          `{"userid":"${processIds.sessionID}","OnInputMic":"IsSpeaking"}`
        );
        break;
      default:
        break;
    }
  };

  const people = [
    {
      name: "Stella Lee",
      imageUrl: "../assets/characters/leenew.jpg",
      desc: "Your nurturing guide to personal growth. Her compassionate approach supports an inclusive space where every step towards self-discovery and emotional health is celebrated.",
      languages: ["Hindi", "English"],
      background: "Latin American ancestry, advocate for gender diversity",
      approach: "Compassionate, intuitive, inclusivity-promoting",
      expertise: "Self-compassion, personal development",
    },
    {
      name: "Stella Sharma",
      imageUrl: "../assets/characters/sharma.jpg",
      desc: "He brings a world of knowledge to your wellness practice. With his strategic warmth and leadership coaching, he's your ally in building emotional resilience and navigating life's stressors.",
      languages: ["Hindi", "English"],
      background: "South Asian descent, extensive travel experience",
      approach: "Warm, strategic, world-wise",
      expertise: "Emotional intelligence, stress reduction, leadership skills",
    },
    {
      name: "Stella George",
      imageUrl: "../assets/characters/georgenew.jpg",
      desc: "He represents the wisdom of diverse traditions in mindfulness. She stands as a beacon of balance and serenity, expertly guiding you through stress with her globally-informed insight.",
      languages: ["Hindi", "English"],
      background: "East Asian heritage, multilingual capabilities",
      approach: "Serene, insightful, culturally aware",
      expertise: "Mindfulness, balance, stress management",
    },
    {
      name: "Stella Adams",
      imageUrl: "../assets/characters/adamsnew.jpg",
      desc: "Her compassionate approach supports an inclusive space where every step towards self-discovery and emotional health is celebrated.",
      languages: ["English", "French"],
      background: "Latin American ancestry, advocate for gender diversity",
      approach: "Compassionate, intuitive, inclusivity-promoting",
      expertise: "Self-compassion, personal development",
    },
    // Add more characters here
  ];

  function execute(id: number) {
    setTimeout(() => {
      console.log(processIds.sessionID, id);

      pixelStreaming?.emitUIInteraction(
        `{"userid":"${processIds.sessionID}","character":"${id}","mode":"${
          window.innerWidth > 540 ? "landscape" : "portrait"
        }"}`
      );
    }, 6000);
    setTimeout(() => {
      selectedStream({
        selected: true,
        name: people[id - 1].name,
        image: people[id - 1].imageUrl,
      });
      setLoader(false);
      setClickToPlayVisible(false);
    }, 10000);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
      className=""
    >
      {loader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 10000000,
            backgroundColor: "rgb(0, 0, 0, 0.5)",
          }}
        >
          <div className="pulse before:bg-violet-800 after:border-violet-800 top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"></div>
        </div>
      )}
      <div
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
        className="  "
        ref={videoParent}
      />
      {clickToPlayVisible && (
        <div className="fixed top-0 left-0 z-[10000] w-full h-screen bg-white overflow-y-scroll  ">
          <div className=" bg-gradient-to-t from-[#5419B6] to-[#7135C0] sm:bg-[#73589B] sm:from-transparent sm:to-transparent  h-[30vh] sm:h-fit  sm:py-4  flex  sm:justify-center ">
            <img
              src="../assets/uwcwhite.png"
              alt=""
              className=" h-[50px] m-3 sm:m-0   "
            />
            <h2 className=" text-2xl absolute  top-[15%] text-center font-bold text-white sm:text-[#344054] w-full">
              Choose Your Stella Avatar
            </h2>
            <button
              className="log-out-button z-[100] "
              onClick={() => {
                closePixelStreamInstance(processIds.userID).then((res) => {
                  if (res?.result === "closed") {
                    let login_detail_str =
                      window.localStorage.getItem("Profile");
                    if (login_detail_str) {
                      let login_detail = JSON.parse(login_detail_str);
                      window.localStorage.setItem(
                        "Profile",
                        JSON.stringify({
                          ...login_detail,
                          can_chat_with_stella: false,
                        })
                      );
                      navigate("/");
                      window.location.reload();
                    } else {
                      console.error("Profile not found in localStorage.");
                    }
                  }
                });
              }}
            >
              <LogoutIcon />
            </button>
          </div>
          <div className=" absolute sm:relative bottom-3  sm:bottom-0 px-4 py-10 bg-white sm:bg-white rounded-2xl sm:rounded-none h-[calc(100vh-25vh)] sm:h-[calc(100vh-82px)] w-full flex justify-center sm:items-center ">
            <div className="bg-white sm:p-10 sm:pt-0 xl:p-36 xl:pt-5  sm:rounded-lg sm:shadow-xl sm:border-[1px] ">
              <h2 className=" text-2xl py-5 lg:mb-6   hidden sm:block lg:px-[4.2rem] lg:text-3xl text-center font-bold text-white sm:text-[#344054] w-full">
                Choose Your Stella Avatar
              </h2>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4  h-fit ">
                {people.map((person, index) => (
                  <div
                    key={person.name}
                    onClick={() => {
                      setLoader(true);
                      execute(index + 1);
                      setCharacter(index + 1);
                    }}
                    className={` w-full md:w-40 xl:w-56 ${
                      character === index + 1 &&
                      "shadow-none md:shadow-lg md:shadow-[#73589B]   border-[3px] border-solid border-[#7F44D6] md:border-none selected--avatar relative rounded-3xl"
                    } relative flex cursor-pointer items-center hover:transition-all hover:shadow-[#73589B] hover:shadow-lg  rounded-2xl shadow-md justify-center flex-col`}
                  >
                    <img
                      className="aspect-[1/1]  rounded-[1.25rem] object-cover"
                      src={person.imageUrl}
                      alt=""
                    />
                    <h3 className=" hidden sm:flex my-2 text-lg font-semibold leading-8  text-gray-900">
                      {person.name}
                    </h3>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
