import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

export default function VideoModal({
  isOpen,
  closeCallback,
  source,
  thumbnail,
  audioSource,
}) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100000]"
        onClose={() => {
          setOpen(false);
          closeCallback();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duratio-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/20 backdrop-blur-md">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="bg-white/70 backdrop-blur-lg  
               overflow-hidden rounded-lg  px-4 pb-4 pt-5 text-left absolute top-1/2 
              left-1/2 translate-y-[-50%] translate-x-[-50%] m
               transition-all sm:my-8 w-[90%] lg:w-1/2 sm:p-6"
              >
                <div className="">
                  <div>
                    {source && (
                      <iframe
                        src={source}
                        className=" rounded-md w-full aspect-video "
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    )}
                    {audioSource && (
                      <>
                        <img
                          src={
                            thumbnail ||
                            "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          className="w-full aspect-video  rounded-3xl mb-2"
                        />
                        {/* <PlayCircleIcon className="text-white/80  w-8 h-8 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full" /> */}
                        {audioSource && (
                          <audio
                            src={audioSource}
                            controls={true}
                            className=" w-full min-w-[250px]"
                          ></audio>
                        )}{" "}
                      </>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
