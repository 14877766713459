import React, { createContext, useContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import StellaHomePage from './StellaHomePage/StellaHomePage';
import './App.css';
import StellaChat from './Routes/StellaChat';
import FeedbackModal from './Feedback/FeedbackModal';


export const StellaContext = createContext();

function App() {
  const [stellaData, setStellaData] = useState({});

  const contextValue = {
    stellaData,
    setStellaData,
  };

  return (
    <>
      <BrowserRouter>
        <StellaContext.Provider value={contextValue}>
          <Routes>
            <Route path="/" element={<StellaHomePage />} />
            <Route path="/chat-with-stella" element={<StellaChat />} />
            <Route path="/feedback" element={<FeedbackModal />} />
          </Routes>
        </StellaContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;

