import React, { useState, useContext, useEffect, useRef } from "react";
import { StellaContext } from "../App";
import "../index.css";
import LoginSidePanel from "./LoginSidePanel";
import { decodeToken } from "react-jwt";
import {
  TokenAPI,
  acceptDisclaimer,
  checkDisclaimerStatus,
  getCustomDataApiHandler,
} from "../APIHandler/ApiHandler";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import DisclaimerModal from "../Modals/DisclaimerModal";

import Cookies from "js-cookie";

function StellaHomePage() {
  const { stellaData, setStellaData } = useContext(StellaContext);
  const [modalPopup, setModalPopup] = useState(false);
  const socketRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const navigate = useNavigate();
  function generateRandomToken(length) {
    return window.crypto
      .getRandomValues(new Uint8Array(length))
      .reduce((acc, item) => acc + ("0" + item.toString(16)).slice(-2), "");
  }
  const session_id = generateRandomToken(16);

  //If customId in URL
  useEffect(() => {
    const custom_id = new URLSearchParams(
      new URL(window.location.href).search
    ).get("custom_id");
    if (custom_id) {
      getCustomDataApiHandler(custom_id).then((res) => {
        if (res?.code === 200) {
          setStellaData((prev) => ({
            ...prev,
            customData: res?.data,
          }));
        } else {
          enqueueSnackbar("Invalid Custom ID", {
            variant: "error",
          });
          navigate("/");
        }
      });
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("stellaToken");
    // console.log(token);
    const localProfile = JSON.parse(window.localStorage.getItem("Profile"));

    if (token === null || token == undefined) {
      handleToken();
    } else {
      if (localProfile?.user_id) {
        const decodedToken = decodeToken(token);
        const localProfile = JSON.parse(window.localStorage.getItem("Profile"));
        setStellaData({
          token: token,
          session_id: session_id,
          profile: {
            ...localProfile,
            user_id: decodedToken?.user_id,
            temp_user_id: decodedToken?.temp_user_id,
          },
        });
      } else {
        handleToken();
      }
    }
  }, []);

  const handleToken = async () => {
    const newToken = await TokenAPI();
    // console.log(newToken);
    const decodedToken = decodeToken(newToken.token);
    // console.log(decodedToken);
    document.cookie = `stellaToken=${newToken.token}; expires=${new Date(
      decodedToken.exp * 1000
    ).toUTCString()}; path=/;`;
    // const domain =
    //   process.env.REACT_APP_ENV === "production"
    //     ? ".unitedwecare.com"
    //     : "localhost";

    // Cookies.set("stellaToken", newToken.token, {
    //   expires: new Date(decodedToken.exp * 1000).toUTCString(), // Expires in 1 day. For an hour, use 1/24.
    //   path: "/",
    //   domain: domain,
    //   secure: true, // Set to true if your site is served over HTTPS
    //   sameSite: "strict", // This setting can help mitigate CSRF attacks
    // });

    setStellaData({
      token: newToken.token,
      session_id: session_id,
      profile: {
        user_id: decodedToken?.temp_user_id,
        temp_user_id: decodedToken?.temp_user_id,
      },
    });
    const localProfile = JSON.parse(window.localStorage.getItem("Profile"));
    window.localStorage.setItem(
      "Profile",
      JSON.stringify({
        acceptedDisclaimer: localProfile?.acceptedDisclaimer || false,
      })
    );
  };
  useEffect(() => {
    if (!stellaData?.token) return;
    if (socketRef.current) {
      socketRef.current.close();
    }
    socketRef.current = new ReconnectingWebSocket(
      `wss://socket.mystella.ai/ws?authorization=${stellaData?.token}`
    );
    socketRef.current.addEventListener("open", (event) => {
      //console.log("WebSocket connection opened!");
      setStellaData((prevData) => ({
        ...prevData,
        socketRef: socketRef,
        chatHistory: [],
      }));
    });
    socketRef.current.addEventListener("message", (event) => {
      let resp_data = JSON.parse(event.data);
      //console.log("resp_data", resp_data);
      if (resp_data?.type === 0) {
        if (resp_data?.login_request?.success) {
          const decodedToken = decodeToken(resp_data?.token);
          // console.log(decodedToken);

          const localProfile = JSON.parse(
            window.localStorage.getItem("Profile")
          );

          window.localStorage.setItem(
            "Profile",
            JSON.stringify({
              ...localProfile,
              acceptedDisclaimer: localProfile?.acceptedDisclaimer || false,
              display_name:
                localProfile?.given_name ||
                resp_data?.login_request?.data?.display_name,
              email:
                localProfile?.email || resp_data?.login_request?.data?.email,
              mobile: "",
              name: localProfile?.name || resp_data?.login_request?.data?.name,
              profile_image:
                localProfile?.picture ||
                resp_data?.login_request?.data?.profile_image,
              user_id: decodedToken?.user_id,
              temp_user_id: decodedToken?.temp_user_id,
            })
          );
          document.cookie = `stellaToken=${
            resp_data?.token
          }; expires=${new Date(
            decodedToken.exp * 1000
          ).toUTCString()}; path=/;`;
          setStellaData((prevData) => ({
            ...prevData,
            profile: {
              display_name:
                localProfile?.given_name ||
                resp_data?.login_request?.data?.display_name,
              email:
                localProfile?.email || resp_data?.login_request?.data?.email,
              mobile: "",
              name: localProfile?.name || resp_data?.login_request?.data?.name,
              profile_image:
                localProfile?.picture ||
                resp_data?.login_request?.data?.profile_image,
              user_id: decodedToken?.user_id,
              temp_user_id: decodedToken?.temp_user_id,
            },
            login_type: prevData.login_type + "_success",
            token: resp_data?.token,
          }));
        } else if (resp_data?.login_request.length > 0) {
          enqueueSnackbar(resp_data?.login_request?.message, {
            variant: "error",
          });
        }
      } else {
        setTimeout(() => {
          if (resp_data?.question === "") return;

          setStellaData((prevData) => {
            if (resp_data?.type === 1) {
              return {
                ...prevData,
                modalData: {
                  show: true,
                  ...resp_data,
                  questionIndex: prevData?.chatHistory?.length,
                },
                chatHistory: [
                  ...prevData.chatHistory,

                  { ...resp_data, liked: false,time_stamp: new Date() },
                ],
              };
            } else {
              return {
                ...prevData,
                chatHistory: [
                  ...prevData.chatHistory,

                  { ...resp_data, liked: false,time_stamp: new Date() },
                ],
              };
            }
          });
        }, 3000);
      }
    });
    // Event handler for when the connection is closed
    socketRef.current.addEventListener("close", (event) => {
      console.log("WebSocket connection closed!");
    });
  }, [stellaData?.token]);
  //console.log(stellaData);
  const handleCheckDisclaimer = async () => {
    const response = await checkDisclaimerStatus(stellaData?.profile?.user_id);
    return response;
  };
  useEffect(() => {
    if (
      !stellaData?.token ||
      !stellaData?.profile?.user_id ||
      !stellaData?.user_logedin
    )
      return;
    handleCheckDisclaimer().then((res) => {
      if (!res) {
        setShowDisclaimer(true);
      } else if (res && stellaData?.user_logedin) {
        navigate("/chat-with-stella");
      }
    });
  }, [
    stellaData?.token,
    stellaData?.user_logedin,
    stellaData?.profile?.acceptedDisclaimer,
  ]);

  const handleAcceptDisclaimerAPI = async () => {
    const response = await acceptDisclaimer(stellaData?.profile?.user_id);
    return response;
  };

  const handleAcceptDisclaimer = (data) => {
    if (data?.accept) {
      handleAcceptDisclaimerAPI().then((res) => {
        if (res === "Consent submitted successfully") {
          setShowDisclaimer(false);
          setStellaData((prevData) => ({
            ...prevData,
            profile: {
              ...prevData.profile,
              acceptedDisclaimer: true,
            },
          }));
          window.localStorage.setItem(
            "Profile",
            JSON.stringify({
              ...JSON.parse(window.localStorage.getItem("Profile")),
              acceptedDisclaimer: true,
            })
          );
        }
      });
    }
  };

  return (
    <>
      <div
        className={`  relative background-gradient min-h-screen h-screen flex flex-col items-center justify-center`}
      >
        <LoginSidePanel
          showPanel={modalPopup}
          closePanelCallback={() => {
            setModalPopup(false);
          }}
        />
        <DisclaimerModal
          isOpen={showDisclaimer}
          closeCallback={handleAcceptDisclaimer}
        />
        <main className="h-screen max-h-screen w-screen overflow-hidden flex flex-col z-10">
          <img
            src="../assets/uwcwhite.png"
            alt="Uwc logo"
            className="w-[300px] lg:w-[400px] p-4 "
          />
          <div className="flex-col-reverse justify-between items-center flex lg:flex-row h-full md:h-max  flex-1 md:flex-none md:justify-between md:m-auto md:max-w-[1440px] pb-[60px] md:pb-0">
            <div className=" max-w-7xl px-6">
              <div className="">
                <div className="relative w-full flex flex-col justify-center ">
                  <h1 className="text-2xl font-bold  text-white sm:text-4xl lg:text-6xl">
                    Stella
                  </h1>
                  <h2 className="text-2xl font-bold  text-white sm:text-4xl lg:text-6xl">
                    24/7 Virtual AI
                  </h2>
                  <h3 className="text-2xl font-bold   text-white sm:text-4xl lg:text-6xl">
                    Mental Health First Aid
                  </h3>
                  <p className="mt-6 text-lg sm:text-2xl leading-8 text-white ">
                    Personalized Companion Throughout Your Care Journey
                  </p>

                  <div className="my-10 flex items-center gap-x-6">
                    <button
                      onClick={() => {
                        if (
                          stellaData?.profile?.user_id &&
                          stellaData?.token &&
                          stellaData?.profile?.profile_image &&
                          stellaData?.profile?.display_name &&
                          stellaData?.profile?.verified
                        ) {
                          setStellaData((prevData) => ({
                            ...prevData,
                            user_logedin: true,
                          }));
                        } else {
                          // console.log(
                          //   stellaData?.profile?.user_id,
                          //   stellaData?.token,
                          //   stellaData?.profile?.profile_image,
                          //   stellaData?.profile?.display_name,
                          //   stellaData?.profile?.verified
                          // );
                          setModalPopup(true);
                        }
                      }}
                      className="rounded-md text-center w-full md:text-2xl lg:w-fit disabled:bg-[#ffffff7c] font-semibold shadow-sm bg-white text-[#692ECB] py-3 px-5 "
                    >
                      Talk To Stella
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5 max-w-[300px] lg:max-w-5xl flex-1 w-full flex items-center justify-center lg:max-h-[720px]">
              <img
                src="../assets/HomePageSvg.png"
                alt="hero"
                className="w-full h-full lg:max-h-[620px] object-contain object-center"
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default StellaHomePage;
