import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function DisclaimerModal({ isOpen, closeCallback }) {
  const [open, setOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

 

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative  z-[100000]"
        onClose={() => {
          setOpen(true);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duratio-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0  bg-[#44444470]  bg-opacity-[80] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full   items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="bg-[#ffffff]  backdrop-blur-lg  
                 rounded-lg   px-4 pb-4 pt-5 text-left absolute top-1/2 
              left-1/2 translate-y-[-50%] translate-x-[-50%] 
               transition-all sm:my-8 w-[95%] lg:w-1/2 xl:w-1/2 sm:p-6"
              >
                <div className="">
                  <p className=" text-center text-3xl pb-2 font-bold">
                    Disclaimer
                  </p>
                  <div
                    className="overflow-y-scroll rounded-md  border-2 p-2 h-[400px]"
             
                  >
                    <p className="text-[14px] md:text-[16px] break-words  text-gray-900">
                      1: If you are thinking about suicide or if you are
                      considering harming yourself or others or if you feel that
                      any other person may be in any danger or if you have any
                      medical emergency, you must immediately call the emergency
                      service number of your country (Please click here for
                      emergency numbers:{" "}
                      <a
                        href="https://en.wikipedia.org/wiki/List_of_emergency_telephone_numbers"
                        target="_blank"
                        className="text-[#73589B]"
                      >
                        https://en.wikipedia.org/wiki/List_of_emergency_telephone_numbers
                      </a>
                      . The suggested list is maintained by Wikipedia. We do not
                      own/verify the authenticity/accuracy of the list). Please
                      notify the relevant authorities, and seek immediate
                      assistance in case of any SOS. The Platform is not
                      designed for use in any of the SOS incidents inclusive of,
                      but not limited to aforementioned cases.
                    </p>
                    <p className="text-[14px] md:text-[16px] mt-2  text-gray-900">
                      2: United We Care does not provide medical advice. All
                      information including text, videos, graphics or written
                      articles are for informational purposes only. No material
                      on our website or App substitutes for professional advice,
                      diagnosis or treatment. We highly recommend that you
                      should consult a qualified professional before making any
                      decisions regarding your health. We also urge our
                      website/App users not to delay or disregard any medical
                      advice from a professional on the basis of anything that
                      you read or viewed on United We Care website or App.
                    </p>
                  </div>
                  <div className="flex items-center justify-between pt-4 flex-wrap">
                    <div className="flex ">
                      <input
                        id="disclaimer"
                        aria-describedby="disclaimer-description"
                        name="disclaimer"
                        type="checkbox"
                        className="h-6  w-6 mr-2 accent-[#73589B] rounded border-gray-300 text-[#73589B] focus:ring-[#70529c]"
                        value={accepted}
                        onChange={() => setAccepted(!accepted)}
                      
                      />
                      <p>
                        {" "}
                        I accept United We Care's{" "}
                        <a
                          href="https://www.unitedwecare.com/in/terms-and-conditions/"
                          target="_blank"
                          className="text-[#73589B]"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.unitedwecare.com/in/privacy-policy/"
                          target="_blank"
                          className="text-[#73589B]"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-center">
                    {" "}
                    <button
                      className=" w-fit text-center  min-w-[200px] my-3 disabled:bg-[#987ac58a]   text-lg rounded-md bg-[#73589B] p-3   leading-6 text-white shadow-sm "
                      disabled={!accepted}
                      onClick={() => {
                        setOpen(false);
                        closeCallback({ accept: accepted });
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
