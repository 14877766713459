import React, { useState, useEffect, useRef } from "react";

export default function OtpInput({ length, disabled,onComplete }) {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  // Use useRef to create a single ref object to store the array of input references
  const inputRefs = useRef(new Array(length).fill(null));

  useEffect(() => {
    // Focus the first input on component mount
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (element, index) => {
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value.charAt(0);

    setOtp(newOtp);

    if (value && index < length - 1) {
      // Focus the next input field
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every((num) => num) && newOtp.length === length) {
      onComplete(newOtp.join(""));
      setOtp(new Array(length).fill(""));
      inputRefs.current[0].focus();
    }
  };

  const handleBackspace = (element, index) => {
    const value = element.value;
    if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="w-fit flex gap-4 my-2 justify-center otp--input">
      {otp.map((_, index) => (
        <input
          className=" focus-visible:outline-none bg-white  border-b-2 border-gray-300 w-10 h-14 text-xl text-center"
          type="number"
          name={`otp-${index}`}
          key={index}
          value={otp[index]}
          disabled={disabled}
          onChange={(e) => {
            handleChange(e.target, index);
          }}
          onKeyDown={(e) =>
            e.key === "Backspace" && handleBackspace(e.target, index)
          }
          ref={(el) => (inputRefs.current[index] = el)} // Assign each input ref
          maxLength="1"
        />
      ))}
    </div>
  );
}
