import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import impressedwhite from "./impressed.png";
import happywhite from "./happy.png";
import sadwhite from "./sad.png";
import angrywhite from "./angry.png";
import unhappywhite from "./unhappy.png";

import impressed_gray from "./impressed_gray.png";
import sad_gray from "./sad_gray.png";
import angry_gray from "./angry_gray.png";
import happy_gray from "./happy_gray.png";
import unhappy_gray from "./unhappy_gray.png";

import angryRed from "./angry_red.png";
import sadYellow from "./sad_yellow.png";
import happy_green from "./happy_green.png";
import unhappy_yellow from "./unhappy_yellow.png";
import impressed_green from "./impressed_green.png";

export default function FeedbackModal({ submit }) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const emotions = [
    {
      src: angry_gray,
      alt: "Angry",
      color: "#F04438",
      selectedSrc: angryRed,
      displaySrc: angrywhite,
      text: "Worst",
    },
    {
      src: sad_gray,
      alt: "Sad",
      color: "#F79009",
      selectedSrc: sadYellow,
      displaySrc: sadwhite,
      text: "Sad",
    },
    {
      src: unhappy_gray,
      alt: "Unhappy",
      color: "#FFC300",
      selectedSrc: unhappy_yellow,
      displaySrc: unhappywhite,
      text: "Unhappy",
    },
    {
      src: happy_gray,
      alt: "Happy",
      color: "#38AB45",
      selectedSrc: happy_green,
      displaySrc: happywhite,
      text: "Great",
    },
    {
      src: impressed_gray,
      alt: "Impressed",
      color: "#2CB223",
      selectedSrc: impressed_green,
      displaySrc: impressedwhite,
      text: "Amazing",
    },
  ];
  const [selectedEmotion, setSelectedEmotion] = useState(
    emotions.findIndex((emotion) => emotion.alt === "Impressed")
  );
  const handleEmotionClick = (index) => {
    setSelectedEmotion(index);
  };

  return (
    <div className="">
      <Transition.Root show={open} as={Fragment} onClose={() => setOpen(true)}>
        <Dialog as="div" className="relative b z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0  background-gradient transition-opacity backdrop-blur-lg" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center  text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  style={{
                    backgroundColor:
                      selectedEmotion !== null
                        ? emotions[selectedEmotion].color
                        : "#694B95",
                  }}
                  className="relative transform  w-full sm:w-[534px] h-[100vh] sm:h-full  sm:rounded-[30px]"
                >
                  <div className="flex flex-col pt-10 items-center justify-center h-full md:h-auto">
                    <div className="p-10">
                      <div className="mx-auto flex pb-2 sm: items-center justify-center rounded-full ">
                        <img
                          className="w-[60px]"
                          src={
                            selectedEmotion !== null
                              ? emotions[selectedEmotion].displaySrc
                              : impressedwhite
                          }
                          alt=""
                        />
                      </div>
                      <div className=" text-center ">
                        <Dialog.Title
                          as="h3"
                          className="text-xl mt-2  font-semibold leading-6  text-white"
                        >
                          {selectedEmotion !== null
                            ? emotions[selectedEmotion].text
                            : "Amazing"}{" "}
                          Experience
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="bg-white p-5 py-10 mt-5 h-full  w-full rounded-t-3xl sm:rounded-[30px] ">
                      <div className="mt-2">
                        <h3 className="text-22 font-semibold  ">
                          What did you like about this{" "}
                          <span className=" font-bold">Conversation?</span>
                        </h3>

                        <div className="flex justify-center h-[60px] gap-4 my-4">
                          {emotions.map((emotion, index) => (
                            <button
                              key={index}
                              className="outline-none border-none"
                              onClick={() => handleEmotionClick(index)}
                            >
                              <img
                                className="w-[60px] "
                                src={
                                  selectedEmotion === index
                                    ? emotion.selectedSrc
                                    : emotion.src
                                }
                                alt={emotion.alt}
                              />
                            </button>
                          ))}
                        </div>
                      </div>{" "}
                      <div className="p-3">
                        <textarea
                          rows={5}
                          name="comment"
                          id="comment"
                          className="block w-full resize-none rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Other suggestions..."
                          defaultValue={""}
                        />
                      </div>
                      <div className="mt-5 px-3 flex gap-2">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md  px-3 py-3 text-sm font-semibold text-[#694B95] shadow-md hover:bg-[#694B95] hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            navigate("/")
                            window.location.reload();
                          }}
                        >
                          Back to home
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-[#694B95]  px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-[#694B95]  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            navigate("/");
                            window.location.reload();
                          }}
                        >
                          Submit Feedback
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
