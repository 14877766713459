import React, { useEffect, useRef, useState } from "react";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import "../App.css";
import { convertBlobToBase64 } from "../lib/utils";
import { fetchSTTWhisper } from "../APIHandler/ApiHandler";

const HandleRecording = ({ shouldchat, handleTranscript }) => {
  // speech recognization new implementation

  const [isRecording, setIsRecording] = useState(false);
  const [transcriptionValue, setTranscriptionValue] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: "audio/webm;codecs=opus",
    });
    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };
    mediaRecorderRef.current.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: "audio/webm;codecs=opus",
      });
      audioChunksRef.current = [];
      await sendAudioToApi(audioBlob);
      // Send the new audioBlob after stopping the recording
    };
    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  };

  const sendAudioToApi = async (blob) => {
    try {
      const base64String = await convertBlobToBase64(blob);
      const formattedBase64 = String(base64String).split(
        "data:audio/webm;codecs=opus;base64,"
      )[1];
      if (formattedBase64) {
        const whisperTranscription = await fetchSTTWhisper(
          JSON.stringify({ base64_audio: formattedBase64 })
        );
        setTranscriptionValue(transcriptionValue + " " + whisperTranscription);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // speech recognization end  implementation
  useEffect(() => {
    if (!shouldchat) {
      setTranscriptionValue("");
    }
  }, [shouldchat]);
  useEffect(() => {
    handleTranscript(transcriptionValue);
  }, [transcriptionValue]);

  return (
    <>
      {isRecording ? (
        <button
          className={`lg:w-[50px] lg:h-[50px] w-[36px] h-[36px] relative aspect-square disabled:cursor-not-allowed cursor-pointer flex justify-center items-center text-white rounded-full ${
            isRecording ? "bg-[#73589B]" : ""
          }`}
          onClick={stopRecording}
          disabled={!shouldchat}
        >
          {/* <MicNoneOutlinedIcon className="text-white" /> */}
          {/* <FiberManualRecordIcon className="text-white" /> */}
          <div className="small_pulse  "></div>
        </button>
      ) : (
        <button
          className="lg:w-[50px] lg:h-[50px]  w-[36px] h-[36px]  relative  aspect-square disabled:cursor-not-allowed cursor-pointer flex justify-center items-center text-white rounded-full"
          onClick={startRecording}
          disabled={!shouldchat}
        >
          <MicOffOutlinedIcon className="text-white" />
        </button>
      )}
    </>
  );
};

export default HandleRecording;