import { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Dialog } from "@headlessui/react";
import { XMarkIcon, CameraIcon } from "@heroicons/react/24/outline";
import "../index.css";
import { CircularProgress } from "@mui/material";
import OtpInput from "./OtpInput";
import imageCompression from "browser-image-compression";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signInAnonymously } from "firebase/auth";
import { auth, storage } from "../firebaseConfig";
import dayjs from "dayjs";
import { decodeToken } from "react-jwt";
import { GoogleLogin } from "@react-oauth/google";
import { useSnackbar } from "notistack";
import { StellaContext } from "../App";
import { OTPVerification } from "../APIHandler/ApiHandler";

function LoginSidePanel({ showPanel, closePanelCallback }) {
  const [email, setEmail] = useState("");
  const { stellaData, setStellaData } = useContext(StellaContext);
  const [isLogin, setisLogin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resendotp, setResendOtp] = useState(false);
  const [timer, setTimer] = useState(0);
  const [otpInput, setOtpInput] = useState(false);
  const [userData, setUserData] = useState({
    showProfileEditPanel: false,
    display_name: "",
    name: "",
    profile_image: "",
    mobile: "",
  });
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("ERROR", errorMessage);
      });
  }, []);
  const uploadInputFileRef = useRef(null);
  useEffect(() => {
    if (resendotp) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [resendotp]);
  useEffect(() => {
    if (stellaData?.login_type === "email_success") {
      setResendOtp(true);
    } else if (stellaData?.login_type?.includes("otp_success_success")) {
      setUserData((prevData) => ({ ...prevData, showProfileEditPanel: false }));
    }
  }, [stellaData?.login_type]);

  const handleSignIn = () => {
    if (email.trim() === "") {
      enqueueSnackbar("Email id field is required!", {
        variant: "error",
      });
      return;
    }
    var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    if (!pattern.test(email)) {
      enqueueSnackbar("Please enter a valid email id!", {
        variant: "error",
      });
      return;
    }
    if (stellaData?.socket) {
      stellaData.socket, send(JSON.stringify());
    }
    const payload = {
      user_id: stellaData?.profile?.user_id,
      type: 0,
      login_response: {
        email: email,
        type: "send_otp",
      },
      data_type: "",
      classify: "User: ",
      time_stamp: Date.now(),
    };

    setStellaData((prevData) => ({
      ...prevData,
      login_type: "email",
    }));
    if (stellaData?.socketRef?.current) {
      stellaData.socketRef.current.send(JSON.stringify(payload));
    }
    setTimer(30);
    setisLogin(true);
  };
  const onGoogleLoginSuccess = (credentialResponse) => {
    const data = decodeToken(credentialResponse?.credential);
    const localProfile = JSON.parse(window.localStorage.getItem("Profile"));

    const newProfileItem = {
      acceptedDisclaimer: localProfile?.acceptedDisclaimer || false,
      display_name: data?.given_name,
      email: data?.email,
      mobile: "",
      name: data?.name,
      profile_image:
        data?.picture ||
        "https://app.uwc.world/Content/App/assets/images/profile-img.png",
      verified: true,
    };
    window.localStorage.setItem("Profile", JSON.stringify(newProfileItem));
    const payload = {
      user_id: stellaData?.profile?.user_id,
      type: 0,
      login_response: {
        email: data?.email,
        type: "google_auth",
      },
      data_type: "",
      classify: "User: ",
      time_stamp: Date.now(),
    };
    setStellaData((prevData) => ({
      ...prevData,
      user_logedin: true,
      login_type: "google",
    }));

    if (stellaData?.socketRef?.current) {
      stellaData.socketRef.current.send(JSON.stringify(payload));
    }
  };
  const handleOTPComplete = async (otp) => {
    setOtpInput(true);
    const headers = {
      "Content-Type": "application/json",
      Deviceid: 123,
      DeviceType: "web",
      lat: 1,
      long: 1,
    };
    const body = JSON.stringify({
      Email: stellaData?.profile?.email,
      OTP: otp,
    });
    const response = await OTPVerification(headers, body);
    if (response === "otp_verified") {
      window.localStorage.setItem(
        "Profile",
        JSON.stringify({
          ...JSON.parse(window.localStorage.getItem("Profile")),
          verified: true,
        })
      );
      setStellaData((prevData) => ({
        ...prevData,
        user_logedin: true,
        login_type: "otp_success",
      }));

      setisLogin(true);
      if (
        !stellaData?.profile?.profile_image ||
        !stellaData?.profile?.name ||
        !stellaData?.profile?.display_name
      ) {
        setUserData((prevData) => ({
          ...prevData,
          name: stellaData?.profile?.name || "",
          display_name: stellaData?.profile?.display_name || "",
          profile_image: stellaData?.profile?.profile_image || "",
          showProfileEditPanel: true,
        }));
      }
    } else {
      enqueueSnackbar("Invalid OTP", {
        variant: "error",
      });
      setOtpInput(false);
    }
  };
  const handleResendOTP = () => {
    const payload = {
      user_id: stellaData?.profile?.user_id,
      type: 0,
      login_response: {
        email: email,
        type: "send_otp",
      },
      data_type: "",
      classify: "User: ",
      time_stamp: Date.now(),
    };
    if (stellaData?.socketRef?.current) {
      stellaData.socketRef.current.send(JSON.stringify(payload));
    }
  };
  const handleChangeProfilePic = async (event) => {
    try {
      let file = Array.from(event.target.files)[0];
      event.target.value = null;
      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        const options = {
          maxSizeMB: 0.5, // (1 MB), adjust according to your needs
          maxWidthOrHeight: 1920, // adjust according to your needs
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const dirName = dayjs().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, compressedFile);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            ////console.log(`Upload progress: ${percent}%`);
          },
          (error) => {
            console.error("Upload error:", error);
            // Handle upload error if needed
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              ////console.log("url", url);
              setUserData((prevState) => ({
                ...prevState,
                profile_image: url,
              }));
              //setProfilePicture(url)
            } catch (error) {
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };
  const handleUpdateUserData = () => {
    const payload = {
      user_id: stellaData?.profile?.user_id,
      registered_id: stellaData?.profile?.user_id,
      type: 0,
      login_response: {
        email: stellaData?.profile?.email,
        type: "update_user",
        name: userData.name,
        profile_image: userData.profile_image,
        mobile: userData.mobile,
        display_name: userData.display_name,
      },
      data_type: "",
      options: [],
      classify: "User: ",
      time_stamp: Date.now(),
    };
    if (stellaData?.socketRef?.current) {
      stellaData.socketRef.current.send(JSON.stringify(payload));
    }
  };
  // console.log(userData);
  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <Dialog
        as="div"
        className=" "
        open={showPanel}
        onClose={closePanelCallback}
      >
        <Dialog.Panel className="fixed   shadow-2xl h-full   background-gradient  pt-40  sm:pt-0 inset-y-0 right-0 z-50 w-full lg:w-1/2   sm:ring-1 sm:ring-gray-900/10">
          <img
            className="sm:block absolute hidden z-[-100] w-full h-full object-cover "
            src="../assets/Homepage.jpg"
          />
          <img
            src="../assets/uwcwhite.png"
            alt="Uwc logo"
            className=" absolute sm:hidden top-2 w-[300px] lg:w-[400px] p-4 "
          />
          <div className="absolute hidden sm:block inset-0 z-[-100]  opacity-50  background-gradient w-full "></div>
          <div className="flex absolute top-2  right-2 items-center justify-end">
            <button
              type="button"
              className=" m-1 rounded-md text-black sm:text-white"
              onClick={closePanelCallback}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
          {!stellaData?.login_type && (
            <div className="flex  items-center h-full   bg-white sm:bg-transparent pt-10  rounded-t-3xl  sm:rounded-none flex-col justify-center px-6  ">
              <div className="h-[75vh] sm:h-full sm:flex items-center flex-col justify-center pb-12 sm:pb-0 overflow-y-scroll">
                <div className="sm:mx-auto mt-6  sm:w-full  sm:max-w-sm">
                  <h2 className=" text-center text-3xl sm:text-4xl  leading-9  sm:text-white text-black">
                    Welcome to Stella!
                  </h2>
                  <h2 className=" text-center text-3xl sm:text-4xl my-2 sm:my-5 leading-9  sm:text-white text-black ">
                    Log In
                  </h2>

                  <div className=" my-5 flex justify-center  ">
                    <GoogleLogin
                      onSuccess={onGoogleLoginSuccess}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      //type="icon"
                      theme="outline"
                      shape="rectangular"
                      size="large"
                      text="continue_with"
                      width={"100%"}
                      ux_mode="popup"
                      logo_alignment="center"
                    />
                  </div>
                </div>

                <div className="sm:mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                  <div className="flex items-center ">
                    <span className="w-full h-[1px] bg-black sm:bg-white"></span>
                    <span className="mx-2 sm:text-white text-black  text-md">
                      Or
                    </span>
                    <span className="w-full h-[1px] bg-black sm:bg-white"></span>
                  </div>
                  <div className="mt-2 flex flex-col justify-center items-center gap-8">
                    <p className="text-center mt-2 sm:text-white text-black ">
                      Log in with your Email Address
                    </p>
                    <input
                      id="email"
                      name="email"
                      autoComplete="email"
                      placeholder="Email Address"
                      required={true}
                      type="email"
                      className="block w-full text-xl  rounded-md border-0 p-3 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:outline-none   sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={(e) => {
                        // Check if the Enter key is pressed without the Shift key
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault(); // Prevent the default action to avoid line break in the textarea
                          handleSignIn();
                        }
                      }}
                    />
                    <button
                      className=" w-fit  min-w-[200px] flex justify-center items-center   text-lg rounded-md bg-[#38AB45] p-3   leading-6 text-white shadow-sm "
                      onClick={handleSignIn}
                      disabled={isLogin}
                    >
                      {isLogin ? (
                        <CircularProgress size={24} className="" />
                      ) : (
                        "Continue "
                      )}
                    </button>
                    {/* <p className=" sm:text-white text-black">
                        Don’t have an account?{" "}
                        <span className=" font-semibold underline">
                          {" "}
                          Create Account
                        </span>
                      </p> */}
                  </div>
                </div>
                <div className="flex mt-12 gap-2 justify-center flex-col items-center">
                  <p className=" text-sm sm:text-white text-black">
                    We are internationally certified!
                  </p>
                  <div className="flex justify-center  items-center gap-4">
                    <img
                      src="../assets/iso.webp.png"
                      alt=""
                      className="inline"
                    />
                    <img
                      src="../assets/hippa.webp.png"
                      alt=""
                      className="inline"
                    />
                    {/* <img
                        src="https://www.unitedwecare.com/wp-content/uploads/2024/01/hitrust.svg"
                        alt=""
                        className="inline"
                      /> */}
                    <img
                      src="https://www.unitedwecare.com/wp-content/uploads/2024/01/aicpa.svg"
                      alt=""
                      className="inline"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {stellaData?.login_type?.includes("email_success") && (
            <div className=" h-full sm:flex justify-center items-center">
              <div className="flex w-full p-4 sm:w-3/4 lg:w-2/3 h-full bg-white sm:rounded-3xl rounded-t-3xl   sm:h-[350px] m-0   shadow-lg  flex-col  px-6  ">
                <p className=" my-6 font-bold text-black text-2xl lg:text-4xl ">
                  Confirm OTP
                </p>
                <p className=" text-slate-500">
                  An OTP has been sent to your email{" "}
                  <span className="text-black font-bold">{email} </span>
                </p>
                <p className=" text-slate-500 my-2">
                  {" "}
                  Please enter the OTP to verify your email.
                </p>
                <div className="flex  items-center px-7 justify-center">
                  <div className="w-max mt-6">
                    <OtpInput
                      disabled={otpInput}
                      length={4}
                      onComplete={handleOTPComplete}
                    />
                    <div
                      className={`flex ${
                        resendotp && "text-[#73589B]"
                      } text-slate-500 items-center justify-between`}
                    >
                      <p>
                        {"00:"}
                        {timer > 9 ? timer : `0${timer}`}{" "}
                      </p>
                      <button
                        onClick={() => {
                          handleResendOTP();
                          setTimer(30);
                          setResendOtp(true);
                        }}
                        disabled={timer > 0}
                      >
                        Resend OTP
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex mt-20 sm:hidden gap-2 justify-center flex-col items-center">
                  <p className=" text-sm sm:text-white text-black">
                    We are internationally certified!
                  </p>
                  <div className="flex justify-center  items-center gap-4">
                    <img
                      src="../assets/iso.webp.png"
                      alt=""
                      className="inline"
                    />
                    <img
                      src="../assets/hippa.webp.png"
                      alt=""
                      className="inline"
                    />
                    {/* <img
                      src="https://www.unitedwecare.com/wp-content/uploads/2024/01/hitrust.svg"
                      alt=""
                      className="inline"
                    /> */}
                    <img
                      src="https://www.unitedwecare.com/wp-content/uploads/2024/01/aicpa.svg"
                      alt=""
                      className="inline"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {userData?.showProfileEditPanel && (
            <div className="h-full  sm:flex justify-center items-center">
              <div className="flex flex-col pt-20 gap-2 sm:gap-0 overflow-auto   w-full sm:w-3/4 lg:w-2/3 h-full sm:h-max p-6  sm:p-8 bg-white sm:rounded-3xl  rounded-t-3xl    shadow-lg">
                {!stellaData?.profile?.profile_image && (
                  <div className="mx-auto">
                    <div className="mx-auto relative">
                      <input
                        id="profile-pic-upload"
                        type="file"
                        ref={uploadInputFileRef}
                        accept="image/*"
                        className="hidden"
                        onChange={handleChangeProfilePic}
                      />
                      <img
                        className="mx-auto h-24 w-auto aspect-square rounded-full"
                        src={
                          userData.profile_image ||
                          "https://app.uwc.world/Content/App/assets/images/profile-img.png"
                        }
                        alt="Display Pic"
                      />

                      <button
                        className="p-2 rounded-full bg-[#73589B] absolute bottom-0 right-4"
                        htmlFor="profile-pic-upload"
                        onClick={() => {
                          uploadInputFileRef.current.click();
                        }}
                      >
                        <CameraIcon className="w-5 h-5 text-slate-50" />
                      </button>
                    </div>

                    <button
                      className="rounded-md bg-[#73589B] text-base font-semibold  text-white shadow-lg   hover:bg-[#73589ba9] mt-2 p-3 px-6  relative"
                      htmlFor="profile-pic-upload"
                      onClick={() => {
                        uploadInputFileRef.current.click();
                      }}
                    >
                      Upload Image
                    </button>
                  </div>
                )}
                {!stellaData?.profile?.display_name && (
                  <div className="mt-4 ">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="text"
                        id="displayName"
                        value={userData.display_name}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            display_name: e.target.value,
                          })
                        }
                        className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                        placeholder="Display Name *"
                      />
                    </div>
                  </div>
                )}
                {!stellaData?.profile?.name && (
                  <div className="mt-4">
                    <div className="mt-2">
                      <input
                        type="text"
                        name="text"
                        id="name"
                        value={userData.name}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            name: e.target.value,
                          });
                        }}
                        className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                        placeholder="Name *"
                      />
                    </div>
                  </div>
                )}
                {!stellaData?.profile?.mobile && (
                  <div className="mt-4">
                    <div className="flex justify-between">
                      <span
                        className="text-sm leading-6 text-gray-500"
                        id="mobile-optional"
                      >
                        Optional
                      </span>
                    </div>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="mobile"
                        id="mobile"
                        value={userData.mobile}
                        onChange={(e) =>
                          setUserData({ ...userData, mobile: e.target.value })
                        }
                        className="block w-full text-xl  rounded-md  border-none p-3 text-black shadow-sm ring-[#73589B] ring-1 ring-inset outline-none  placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                        placeholder=" Mobile No"
                        aria-describedby="mobile-optional"
                      />
                    </div>
                  </div>
                )}
                <div className="my-6 py-4 lg:mt-6">
                  <button
                    type="submit"
                    disabled={
                      userData.name.trim() === "" ||
                      userData.display_name.trim() === "" ||
                      userData.profile_image.trim() === ""
                    }
                    className="flex w-full justify-center text-lg font-semibold  rounded-md bg-[#73589B] disabled:bg-[#73589b93] p-3   leading-6 text-white shadow-sm "
                    onClick={handleUpdateUserData}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

export default LoginSidePanel;
