import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import "../App.css";
export default function StocasticModal({ isOpen, closeCallback, data }) {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  // console.log(data);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100000000] lg:hidden"
        onClose={() => {
          setOpen(false);
          closeCallback();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed  inset-0  w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-black/20 backdrop-blur-md">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="  bg-white/70 max-h-[450px] backdrop-blur-lg  
               overflow-auto stocastic-modal rounded-lg  px-4 pb-4 pt-5 text-left absolute top-1/2 
              left-1/2 translate-y-[-50%] translate-x-[-50%] m
               transition-all sm:my-8 w-[90%] sm:w-1/2 sm:p-6 "
              >
                <div className="">
                  <div className="flex flex-col rounded-md justify-end pb-2">
                    {/* <button
                      type="button"
                      className="flex   justify-center rounded-md bg-[#73589B] text-white"
                      onClick={() => {
                        setOpen(false);
                        closeCallback();
                      }}
                    >
                      <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                    </button> */}
                    {data?.video_url && (
                      <iframe
                        src={data?.video_url}
                        width={"100%"}
                        className=" rounded-md w-full aspect-video h-[200px] md:h-[350px]"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    )}

                    {data?.thumbnail_img && data?.audio_url && (
                      <>
                        <img
                          src={
                            data?.thumbnail_img ||
                            "https://firebasestorage.googleapis.com/v0/b/united-for-her.appspot.com/o/odoo%2Fopen%2FThumbnail.png?alt=media"
                          }
                          className="w-full  rounded-3xl mb-2"
                        />
                        {/* <PlayCircleIcon className="text-white/80  w-8 h-8 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full" /> */}
                        {data?.audio_url && (
                          <audio
                            src={data?.audio_url}
                            controls={true}
                            className=" w-full min-w-[250px]"
                          ></audio>
                        )}{" "}
                      </>
                    )}

                    <p className="text-[16px] text-black mt-3">
                      {data?.question}
                    </p>
                    <div
                      className="highlightHTML text-[14px] break-words break-all"
                      dangerouslySetInnerHTML={{
                        __html: data?.html?.replace(/\n\s+/g, ""),
                      }}
                    />
                    {data?.options?.length > 0 && (
                      <div className="flex  flex-col my-2 px-8">
                        {data.options.map((option, index) => (
                          <button
                            key={index}
                            onClick={() =>
                              closeCallback({
                                option: option,
                                questionIndex: data?.question_id,
                                optionIndex: index,
                                options: data?.options.map((opt) => ({
                                  option_id: opt.option_id,
                                  option_technical_name: opt.option_technical_name,
                                  option_display_name: opt.option_display_name,
                                  sequence: opt.sequence,
                                  is_mandatory: opt.is_mandatory,
                                  is_selected: opt === option
                                })),
                              })
                            }
                            className={` text-sm min-w-[100px]  hover:bg-[#73589B]   hover:text-white border border-[#00000059]  text-black rounded-full my-1 px-4 p-2 `}
                          >
                            {option.option_display_name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>

               
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
