import { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import axios from "axios";

const VideoRecorder = ({ startCam, sessionId, token }) => {
  const webcamRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);
  const [error, setError] = useState(""); // State to store error messages

  useEffect(() => {
    const startCamera = async () => {
      try {
        const constraints = { video: true, audio: false };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        webcamRef.current.srcObject = stream;
        setError(""); // Clear any previous errors
      } catch (error) {
        if (
          error.name === "NotAllowedError" ||
          error.name === "PermissionDeniedError"
        ) {
          setError(
            "Camera access denied. Please allow camera access in your browser settings."
          );
        } else {
          setError(`Error accessing camera: ${error.message}`);
        }
        console.error("Error accessing camera:", error);
      }
    };
    const stopCamera = async () => {
      if (webcamRef.current && webcamRef.current.srcObject) {
        await webcamRef.current.srcObject
          .getTracks()
          .forEach((track) => track.stop());
        webcamRef.current.srcObject = null;
      }
      setError(""); // Clear any error when stopping the camera
    };

    if (startCam) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [startCam]);

  useEffect(() => {
    const capture = () => {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };
          const data = { base64_image: imageSrc, session_id: sessionId };

          axios
            .post("https://socket.mystella.ai/stella/upload_image", data, {
              headers,
            })
            .then((response) =>
              console.log("Image uploaded successfully:", response.data)
            )
            .catch((error) => console.error("Error uploading image:", error));
        }
      }
    };

    if (startCam) {
      const id = setInterval(capture, 1000);
      setIntervalId(id);
    } else if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [startCam, sessionId]);

  return (
    <>
      {error && <div style={{ color: "red" }}>{error}</div>}
      {startCam && (
        <Webcam
          ref={webcamRef}
          audio={false}
          mirrored={true}
          screenshotFormat="image/jpeg"
          videoConstraints={{ width: 1280, height: 720 }}
          className="webcam z-[90] rounded-md"
        />
      )}
    </>
  );
};

export default VideoRecorder;
