import React, { useContext, useEffect, useRef, useState } from "react";
import {
  closePixelStreamInstance,
  runPixelStreamInstance,
} from "../APIHandler/ApiHandler";
import { StellaContext } from "../App";
import { PixelStreamingWrapper } from "../Components/PixelStreamingWrapper.tsx";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import {
  CallEndOutlined,
  ChatOutlined,
  Favorite,
  SpeakerNotesOffOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { Switch } from "@headlessui/react";
import StellaConversationViewer from "../Components/StellaConversationViewer.jsx";
import HandleRecording from "../Components/HandleRecording.jsx";
import VideoRecorder from "../Components/VideoRecorder.jsx";
import DocumentAttachment from "../Components/DocumentAttachment.jsx";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function StellaChat() {
  const { stellaData, setStellaData } = useContext(StellaContext);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState("");
  const textareaRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState(0);
  const [showChat, setShowChat] = useState(true);
  const [shouldchat, setShouldChat] = useState(false);
  const [stellaReaction, setStellaReaction] = useState(false);
  const [startCamera, setStartCamera] = useState(false);
  const [showHeartbeat, setShowHeartbeat] = useState(false);


  useEffect(() => {
    if (stellaData?.profile?.temp_user_id && stellaData?.user_logedin) {
      LaunchprocessIds();
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Ensure the textarea is mounted
      if (value.trim() === "") {
        console.log("in");
        textarea.style.height = "48px";
        setTextareaHeight(48);
      } else {
        textarea.style.height = "25px"; // Reset height to calculate scrollHeight correctly
        textarea.style.height = `${Math.min(textarea.scrollHeight, 125)}px`;
        setTextareaHeight(Math.min(textarea.scrollHeight, 125));
      }
    }
  }, [value]); // Re-run effect when 'value' changes

  const LaunchprocessIds = async () => {
    if (!stellaData?.profile?.temp_user_id) return;
    let login_detail = JSON.parse(window.localStorage.getItem("Profile"));
    setLoader(true);
    const resp = await runPixelStreamInstance(
      stellaData?.profile?.user_id
    );
    if (resp.success) {
      setTimeout(() => {
        setStellaData({
          ...stellaData,
          PortsInfo: resp,
        });

        setLoader(false);
      }, 6000);

      window.localStorage.setItem(
        "Profile",
        JSON.stringify({
          ...login_detail,
          can_chat_with_stella: true,
        })
      );
    } else {
      enqueueSnackbar(resp.message, {
        variant: "error",
      });

      window.localStorage.setItem(
        "Profile",
        JSON.stringify({
          ...login_detail,
          can_chat_with_stella: false,
        })
      );
      navigate("/");
      setTimeout(() => {
        setLoader(false);
        window.location.reload();
      }, 2000);
    }
  };

  const handleSendMessage = (data) => {
    console.log(data?.message);
    if (data?.message?.trim() === "") return;
    setShouldChat(false);
    setValue("");
    const type = data?.options ? 1 : 2;
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: type,
      response: data?.message || data?.option?.option_display_name, // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.user_id,
      registered_id: stellaData?.profile?.user_id,
      classify: "User: ",
      responseFrom: "User",
      options:
        data?.options ||
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]?.options ||
        [],
      mode: stellaData?.customData?.mode?.code || "gn01",
      welcome_message: stellaData?.customData?.welcome_message,
      expert_id: stellaData?.customData?.expert?.id,
    };
    stellaData?.socketRef?.current?.send(JSON.stringify(currDataNew));

    let temp = [...stellaData?.chatHistory];
    if (data?.options?.length > 0) {
      console.log("inside options");
      temp[data?.questionIndex] = {
        ...temp[data?.questionIndex],
        options: data?.options,
        disabled: true,
      };
    }

    setStellaData({
      ...stellaData,
      modalData: {},
      chatHistory: [...temp, currDataNew],
    });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (e) => {
    console.log(e);

    if (e && e.code === "Enter" && !e.shiftKey) {
      e.preventDefault();

      handleSendMessage({ message: value });
      setValue("");
    }
  };

  // console.log(stellaData);
  let wordCount = 0;
  let previousMultiple = 0;

  const handleTranscript = (transcript) => {
    //console.log(transcript);
    if (transcript.trim() === "") {
      wordCount = 0;
      previousMultiple = 0;

      return;
    }
    const words = transcript.split(/\s+/);
    wordCount += words.length;

    // console.log("Current word count:", wordCount);
    setValue(transcript);
    // Check if the current word count is a new multiple of 10
    if (wordCount % 15 === 0 && wordCount !== previousMultiple) {
      console.log("Called function");
      setStellaReaction(true);
      // Update the previous multiple of 10
      previousMultiple = wordCount;
    } else {
      setStellaReaction(false);
    }
  };

  const handleEOC = () => {
    stellaData?.socketRef?.current?.send(
      JSON.stringify({
        service_id: 50, // service identifier
        message_id: "",
        question_id: "", //question_id
        type: "",
        response: "<|end_of_session|>", // option_display_name
        time_stamp: Date.now(),
        session_id: stellaData?.session_id,
        user_id: stellaData?.profile?.user_id,
        classify: "User",
        responseFrom: "User",
        registered_id: stellaData?.profile?.user_id,
        mode: stellaData?.customData?.mode?.code || "gn01",
        welcome_message: stellaData?.customData?.welcome_message,
        expert_id: stellaData?.customData?.expert?.id,
      })
    );
    closePixelStreamInstance(
      stellaData?.profile?.user_id
    );
    setShouldChat(false);

    let login_detail = JSON.parse(window.localStorage.getItem("Profile"));
    window.localStorage.setItem(
      "Profile",
      JSON.stringify({
        ...login_detail,
        can_chat_with_stella: false,
      })
    );

    navigate("/feedback");
  };

  const handleHeart = () => {
    setShowHeartbeat(!showHeartbeat);
    setShouldChat(false);
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: 2,
      response: "sending you some love...", // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.user_id,
      registered_id: stellaData?.profile?.user_id,
      classify: "User: ",
      responseFrom: "User",
      options: null,
      mode: stellaData?.customData?.mode?.code || "gn01",
      welcome_message: stellaData?.customData?.welcome_message,
      expert_id: stellaData?.customData?.expert?.id,
    };

    stellaData?.socketRef?.current?.send(JSON.stringify(currDataNew));
    setTimeout(() => {
      setShowHeartbeat(false);
    }, 6000);
  };

  const handleDocumentUpload = (ocrText) => {
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: 2,
      response: "Explain me what is in the document?", // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.user_id,
      registered_id: stellaData?.profile?.user_id,
      classify: "User: ",
      responseFrom: "User",
      options: null,
      mode: stellaData?.customData?.mode?.code || "gn01",
      welcome_message: stellaData?.customData?.welcome_message,
      expert_id: stellaData?.customData?.expert?.id,
      ocr_text: ocrText,
    };

    stellaData?.socketRef?.current?.send(JSON.stringify(currDataNew));
  };

  return (
    <div className="min-h-screen w-screen flex  bg-[#DFE1E5]">
      {loader && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            zIndex: 10000000,
          }}
          className=" background-gradient"
        >
          <div className="pulse before:bg-white after:border-white top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%]"></div>
        </div>
      )}

      <div className={` w-full  ${showChat ? "" : " "}  relative  `}>
        {stellaData?.PortsInfo?.httpsPort && (
          <PixelStreamingWrapper
            initialSettings={{
              AutoPlayVideo: true,
              AutoConnect: true,
              ss:
                process.env.REACT_APP_WEBSOCKET_URL +
                ":" +
                stellaData?.PortsInfo?.httpsPort,
              StartVideoMuted: false,
              HoveringMouse: true,
              MatchViewportRes: true,
              MouseInput: true,
              TouchInput: false,
              preferSFU: true,
              WebRTCMaxBitrate: 2000000,
            }}
           
           
            processIds={{
              ...stellaData?.PortsInfo,
              userID: stellaData?.profile?.user_id,
              sessionID: stellaData?.session_id,
            }}
            selectedStream={(data) => {
              if (data?.selected) {
                setStellaData({
                  ...stellaData,
                  characterProfile: data,
                });
                setTimeout(() => {
                  stellaData?.socketRef?.current?.send(
                    JSON.stringify({
                      service_id: 50, // service identifier
                      message_id: "",
                      question_id: "", //question_id
                      type: "",
                      response: "Hello! I am Stella", // option_display_name
                      time_stamp: Date.now(),
                      session_id: stellaData?.session_id,
                      user_id: stellaData?.profile?.user_id,
                      classify: "User",
                      responseFrom: "User",
                      registered_id: stellaData?.profile?.user_id,
                      mode: stellaData?.customData?.mode?.code || "gn01",
                      welcome_message: stellaData?.customData?.welcome_message,
                      expert_id: stellaData?.customData?.expert?.id,
                    })
                  );
                }, 2000);
              }
              if (data?.speak) {
                setShouldChat(true);
              }
            }}
            stellaReaction={stellaReaction}
          />
        )}

        {/* VideoCam Feed */}
        <div className="absolute top-[10%]  w-36 sm:w-60 md:aspect-[3/4] aspect-[9/18] left-[7%] p-2 z-[50]">
          <VideoRecorder
            startCam={startCamera}
            sessionId={stellaData?.session_id}
            token={stellaData?.token}
          />
        </div>

        <div
          className="absolute bottom-16 hidden lg:flex left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full glass  gap-7 p-2 z-[1000] "
          style={{
            background:
              "linear-gradient(254.47deg, rgba(255, 255, 255, 0.2) 10.81%, rgba(255, 255, 255, 0.2) 117.24%)",
          }}
        >
          {!loader && (
            <HandleRecording
              handleTranscript={handleTranscript}
              shouldchat={shouldchat}
            />
          )}

          <button
            className={`w-[50px] aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center rounded-full ${
              startCamera ? "bg-[#73589B]" : ""
            }`}
            disabled={!shouldchat}
            onClick={() => setStartCamera(!startCamera)}
          >
            {startCamera ? (
              <VideocamOutlined className={`text-white`} />
            ) : (
              <VideocamOffOutlined className={`text-white `} />
            )}
          </button>
          <div
            className={`w-[50px] h-[50px]  aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full ${
              showChat ? " bg-[#73589B]" : ""
            }`}
            onClick={() => {
              setShowChat(!showChat);
            }}
          >
            {showChat ? (
              <ChatOutlined className="text-white" />
            ) : (
              <SpeakerNotesOffOutlined className="text-white" />
            )}
          </div>
          <div
            onClick={handleEOC}
            className="w-[50px] h-[50px] bg-white aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full"
          >
            <CallEndOutlined className=" text-red-600 rounded-full " />
          </div>
        </div>
        {showHeartbeat && (
          <ul className="hearts  z-[1000000] flying">
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
            <li className="heart"></li>
          </ul>
        )}
        <button
          disabled={!shouldchat}
          onClick={handleHeart}
          className=" flex w-[50px] h-[50px] disabled:cursor-not-allowed  z-[100]  bg-[#ff5b5b] rounded-full absolute  bottom-20 lg:bottom-24 left-2 lg:left-[7%]   cursor-pointer shadow-lg shadow-[#fe6c6c9d]    justify-evenly items-center"
        >
          <Favorite className="text-white" />
        </button>
      </div>
      <div className="absolute flex items-center lg:hidden z-[1000] top-[5%] right-3 ">
        <DocumentAttachment handleTranscript={handleDocumentUpload} />
        <Switch
          checked={showChat}
          onChange={(e) => {
            setShowChat(e);
            // console.log(e);
          }}
          className={classNames(
            "bg-[#F6EFFA] relative inline-flex h-[35px] w-[70px] flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-none"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={classNames(
              showChat ? "translate-x-9" : "translate-x-0",
              "pointer-events-none relative inline-block h-[30px] w-[30px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          >
            <span
              className={classNames(
                showChat
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <img src="/assets/Icons/showchatoff.png" />
            </span>
            <span
              className={classNames(
                showChat
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <img src="/assets/Icons/showchat.png" />
            </span>
          </span>
        </Switch>
      </div>
      <div
        className={`absolute   h-screen lg:bg-transparent pt-60 lg:pt-0 m-0 bottom-0 right-0 z-50 w-full lg:w-[400px] lg:h-[80vh] lg:ring-1 lg:ring-gray-900/10 lg:top-[46%] lg:right-[1%] lg:-translate-x-[5%] lg:-translate-y-1/2 lg:rounded-xl ${
          showChat
            ? "bg-gradient-to-t lg:bg-white/10 flex lg:flex-col"
            : "lg:hidden"
        } from-[#000000c5] to-[#00000000] lg:from-transparent lg:to-transparent lg:backdrop-blur-sm`}
      >
        <div className=" bg-white lg:bg-white/40 h-[82px] lg:h-max  w-full hidden lg:flex p-10 py-5 font-bold lg:z-10  text-2xl lg:text-lg lg:py-3 lg:rounded-t-xl">
          Chats
        </div>

        <div className="lg:flex absolute lg:relative bottom-0 w-full lg:h-full lg:bg-white/30 lg:rounded-b-xl  flex-col justify-between  ">
          <StellaConversationViewer showChat={showChat} />

          <div
            className={` fixed w-full lg:relative bottom-0 px-2 flex gap-3  lg:bg-white/20 justify-between items-end  lg:rounded-b-xl`}
          >
            <div className="hidden lg:block pb-1 ps-2">
              <DocumentAttachment handleTranscript={handleDocumentUpload} />
            </div>
            <div className="lg:hidden  h-full min-w-[45px]  ">
              <button
                className={`min-w-[45px] h-[45px] bg-[#73589B] absolute bottom-[12px] lg:hidden aspect-square disabled:cursor-not-allowed cursor-pointer flex flex-col justify-center items-center rounded-full`}
                disabled={!shouldchat}
                onClick={() => setStartCamera(!startCamera)}
              >
                {startCamera ? (
                  <VideocamOutlined className={`text-white`} />
                ) : (
                  <VideocamOffOutlined className={`text-white `} />
                )}
              </button>
            </div>

            <div
              className="textarea-container w-full rounded "
              style={{
                minHeight: "64px",
                height:
                  textareaHeight > 125
                    ? 145
                    : textareaHeight === 48
                    ? "72px"
                    : textareaHeight + 30 + "px",

                position: "relative",
              }}
            >
              <textarea
                ref={textareaRef}
                value={value}
                disabled={!shouldchat}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                style={{
                  position: "absolute",

                  width: "100%",
                  minHeight: "25px",
                  height: "25px",
                  maxHeight: "125px",
                  boxSizing: "border-box",
                  resize: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  background:
                    "linear-gradient(254.47deg, rgba(255, 255, 255, 0.2) 10.81%, rgba(255, 255, 255, 0.2) 117.24%)",
                }}
                className="lg:p-3 px-10 py-3 glass  rounded-s-3xl text-white lg:text-black left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] lg:bg-white rounded-e-3xl bg-transparent border-0 ring-0 outline-0"
                placeholder="Type Something..."
              />

              <div
                className="lg:hidden bottom-[15px]"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: "1000000",
                }}
              >
                <IconButton
                  sx={{ padding: "3px" }}
                  onClick={() => handleSendMessage({ message: value })}
                  disabled={value?.trim() === ""}
                >
                  <div className="min-h-[36px]  min-w-[36px] bg-[#73589B] lg:hidden flex  items-center justify-center rounded-full">
                    <img
                      src="../assets/Icons/send-2.png"
                      alt="Add User"
                      className="w-[24px]"
                    />
                  </div>
                </IconButton>
              </div>

              <div
                className="lg:hidden bottom-[18px] "
                style={{
                  position: "absolute",
                  // padding:"6px",
                  left: 4,
                  zIndex: "1000",
                }}
              >
                <HandleRecording
                  handleTranscript={handleTranscript}
                  shouldchat={shouldchat}
                />
              </div>
            </div>
            <div className="lg:hidden min-w-[50px] ">
              <div
                onClick={handleEOC}
                className="min-w-[50px] h-[50px] absolute bottom-[12px] bg-white aspect-square cursor-pointer flex flex-col justify-center items-center rounded-full"
              >
                <CallEndOutlined className=" text-red-600 rounded-full " />
              </div>
            </div>

            <div className="hidden lg:block  pb-1 pe-2">
              <IconButton
                onClick={() => handleSendMessage({ message: value })}
                disabled={value?.trim() === ""}
                className=""
              >
                <div className="min-h-[50px]  min-w-[50px] bg-[#73589B] hidden lg:flex  items-center justify-center rounded-full">
                  <img
                    src="../assets/Icons/send-2.png"
                    alt="Add User"
                    className="w-[30px]"
                  />
                </div>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StellaChat;