import React, { useContext, useEffect, useRef, useState } from "react";
import { StellaContext } from "../App";
import { PlayCircle } from "@mui/icons-material";
import StocasticModal from "../Modals/StocasticModal";
import VideoModal from "../Modals/VideoModal";

function StellaConversationViewer({ showChat }) {
  const { stellaData, setStellaData } = useContext(StellaContext);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoOpen, setVideoOpen] = useState(false);

  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [stellaData?.chatHistory]);

  const handleSendMessage = (data) => {
    if (data?.message?.trim() === "") return;
    const type = data?.options ? 1 : 2;
    let currDataNew = {
      service_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.service_id || 50, // service identifier
      message_id: "",
      question_id:
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]
          ?.question_id || 0, //question_id
      type: type,
      response: data?.message || data?.option?.option_display_name, // option_display_name
      time_stamp: Date.now(),
      session_id: stellaData?.session_id,
      user_id: stellaData?.profile?.user_id,
      registered_id: stellaData?.profile?.user_id,
      classify: "User",
      responseFrom: "User",
      options:
        data?.options ||
        stellaData?.chatHistory[stellaData?.chatHistory?.length - 1]?.options ||
        [],
      mode: stellaData?.customData?.mode?.code || "gn01",
      welcome_message: stellaData?.customData?.welcome_message,
      expert_id: stellaData?.customData?.expert?.id,
    };
    stellaData?.socketRef?.current?.send(JSON.stringify(currDataNew));

    let temp = [...stellaData?.chatHistory];
    if (data?.options?.length > 0) {
      console.log("inside options");
      temp[data?.questionIndex] = {
        ...temp[data?.questionIndex],
        options: data?.options,
        disabled: true,
      };
    }

    setStellaData({
      ...stellaData,
      modalData: {},
      chatHistory: [...temp, currDataNew],
    });
  };
  const [visibleMessages, setVisibleMessages] = useState(new Set());
  const messageRefs = useRef([]);
  useEffect(() => {
    // Ensure refs array matches the chatHistory size
    messageRefs.current = messageRefs.current.slice(
      0,
      stellaData?.chatHistory?.length
    );

    const observer = new IntersectionObserver(
      (entries) => {
        // Use a functional update to ensure we always have the current state
        setVisibleMessages((currentVisibleMessages) => {
          // Create a new Set from the current state to ensure immutability
          const updatedVisibleSet = new Set(currentVisibleMessages);

          entries.forEach((entry) => {
            // Ensure index is interpreted as a number
            const index = Number(entry.target.dataset.index);

            if (entry.isIntersecting) {
              // console.log(`Message ${index} is ${entry.intersectionRatio, entry.boundingClientRect.height} visible`);

              if (
                entry.intersectionRatio === 1 ||
                entry.boundingClientRect.height > 150
              ) {
                updatedVisibleSet.add(index);
              } else {
                // console.log(`Message ${index} is not fully visible`);
                updatedVisibleSet.delete(index);
              }
            } else {
              // console.log(`Message ${index} is not visible`);
              updatedVisibleSet.delete(index);
            }
          });

          // Return the updated Set as the new state
          return updatedVisibleSet;
        });
      },
      {
        root: null,
        threshold: [0, 1],
      }
    );

    messageRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [stellaData?.chatHistory]);
  useEffect(() => {
    if (showChat) {
      setVisibleMessages(
        new Set(stellaData?.chatHistory?.map((message, index) => index))
      );
    }
  }, [showChat]);

  return (
    <>
      {" "}
      <div
        ref={chatHistoryRef}
        className={`px-2 lg:bg-transparent  h-[40vh] mt-5 lg:mt-0 fixed bottom-8 lg:bottom-0 lg:relative w-full p-4 overflow-scroll lg:h-full ${
          showChat ? "" : "hidden"
        } flex flex-col`}
      >
        {stellaData?.chatHistory?.map((item, i) => {
          return (
            <div
              key={i}
              tabIndex="0"
              ref={(el) => (messageRefs.current[i] = el)} // Assign ref
              data-index={i} // Custom attribute to identify the message
              className={`message txtmsg${i} ${
                !visibleMessages.has(i) &&
                " transition-opacity opacity-0 lg:opacity-100"
              } `}
            >
              <div
                className={`flex ${
                  item?.classify?.includes("User") && " flex-row-reverse ps-3"
                }    lg:text-black  mb-2 gap-2    m-0`}
              >
                <div className="flex items-center gap-2"></div>
                {item?.classify?.includes("Therapist") ? (
                  <img
                    src={stellaData?.characterProfile?.image}
                    alt="assistant image"
                    className="w-[40px] h-[40px] object-cover aspect-square rounded-full border border-solid border-white"
                  />
                ) : (
                  item?.classify?.includes("User") && (
                    <img
                      loading="lazy"
                      src={`${stellaData?.profile?.profile_image}`}
                      className="w-[40px] min-w-[40px] h-[40px]  object-cover rounded-full border border-solid border-white"
                      alt="user image"
                    />
                  )
                )}
                <div>
                  <div
                    className={`${
                      item.media_type == "video" || item.media_type == "audio"
                        ? " rounded-2xl p-4 "
                        : ""
                    }  rounded-xl ${
                      item?.classify?.includes("User")
                        ? "lg:bg-white/70  text-white lg:text-slate-800"
                        : "lg:bg-white/30 lg:text-black text-white lg:shadow-[#73589B14] lg:shadow-xl"
                    } bg  px-4 py-0 md:py-3`}
                  >
                    {item?.classify?.includes("User") ? (
                      <p className="mb-2 text-[14px] text-right  font-bold">
                        {stellaData?.profile.display_name}
                      </p>
                    ) : (
                      <p className="text-[14px] text-left font-bold mb-2">
                        {stellaData?.characterProfile?.name}
                      </p>
                    )}
                    {(item.media_type == "video" ||
                      item.media_type == "audio") &&
                      item.thumbnail_img !== undefined && (
                        <div
                          className="relative w-fit  hover:cursor-pointer"
                          onClick={() => {
                            setThumbnailUrl(item.thumbnail_img);
                            setVideoUrl(item.video_url);
                            setVideoOpen(true);
                          }}
                        >
                          <img
                            src={item?.thumbnail_img}
                            className="w-full  rounded-3xl mb-2"
                          />
                          <PlayCircle className="text-white/80  w-8 h-8 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full" />
                        </div>
                      )}
                    {item?.thumbnail_img && item?.audio_url && (
                      <>
                        {item?.audio_url && (
                          <div
                            className="relative w-fit  hover:cursor-pointer"
                            onClick={() => {
                              setThumbnailUrl(item.thumbnail_img);
                              setAudioUrl(item.audio_url);
                              setVideoOpen(true);
                            }}
                          >
                            <img
                              src={item?.thumbnail_img}
                              className="w-full  rounded-3xl mb-2"
                            />
                            <PlayCircle className="text-white/80  w-8 h-8 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] rounded-full" />
                          </div>
                        )}{" "}
                      </>
                    )}

                    {item?.classify?.includes("User") ? (
                      <p className="mb-2 text-[14px] leading-snug text-left  ">
                        {item?.response}
                      </p>
                    ) : (
                      <p className="text-[14px]  leading-snug  text-left  mb-2">
                        {item?.question}
                      </p>
                    )}
                    {item?.html && (
                      <div
                        className="highlightHTML rounded-md p-2 bg-white text-[14px] break-words break-all"
                        dangerouslySetInnerHTML={{
                          __html: item?.html?.replace(/\n\s+/g, ""),
                        }}
                      />
                    )}
                    {item?.options?.length > 0 &&
                      item?.classify?.includes("Therapist") && (
                        <div className="lg:flex hidden  gap-2 flex-wrap my-2">
                          {item.options.map((option, index) => (
                            <button
                              disabled={item?.disabled}
                              key={index}
                              onClick={() =>
                                handleSendMessage({
                                  option: option,
                                  questionIndex: i,
                                  options: item?.options.map((opt) => ({
                                    option_id: opt.option_id,
                                    option_technical_name:
                                      opt.option_technical_name,
                                    option_display_name:
                                      opt.option_display_name,
                                    sequence: opt.sequence,
                                    is_mandatory: opt.is_mandatory,
                                    is_selected: opt === option,
                                  })),
                                })
                              }
                              className={` text-sm min-w-[100px] ${
                                option?.is_selected
                                  ? "bg-[#73589B]  text-white"
                                  : " border  text-[#73589B] bg-white lg:bg-white/50 lg:text-slate-800"
                              }  rounded-xl px-4 p-2 lg:text-xs`}
                            >
                              {option.option_display_name}
                            </button>
                          ))}
                        </div>
                      )}
                  </div>
                  <p
                    className={`${
                      item?.classify?.includes("User")
                        ? "text-right"
                        : "text-left"
                    } mt-1 hidden lg:block text-[12px] text-[#00000099] lg:text-white`}
                  >
                    {new Date(item?.time_stamp).toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <VideoModal
        isOpen={videoOpen}
        closeCallback={() => {
          setVideoOpen(false);
          setAudioUrl("");
          setVideoUrl("");
        }}
        source={videoUrl}
        thumbnail={thumbnailUrl}
        audioSource={audioUrl}
      />
      <StocasticModal
        isOpen={stellaData?.modalData?.show || false}
        closeCallback={(data) => {
          if (data?.questionIndex) {
            handleSendMessage({
              option: data?.option,
              questionIndex: data?.questionIndex,
              optionIndex: data?.optionIndex,
              options: data?.options,
            });
          }
        }}
        data={stellaData?.modalData}
      />
    </>
  );
}

export default StellaConversationViewer;
