import axios from "axios";

export const TokenAPI = async () => {
  const requestOptions = {
    method: "POST",
    redirect: "follow",
  };
  const url = "https://socket.mystella.ai/stella/get-token";
  try {
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const OTPVerification = async (header, body) => {
  const url = process.env.REACT_APP_OTP_ACTION_URL;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: body,
    });
    const responseData = await response.json();
    if (responseData.ResponseCode === 200) {
      return "otp_verified";
    } else if (responseData.ResponseCode === 400) {
      return "otp_not_verified";
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export const runPixelStreamInstance = async (userid) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:8000/allocate-port/userId=${userid}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }
    const data = await response.json();



    if (data.statusCode === 200) {
      const {
        httpPort,
        httpsPort,
        SFUPort,
        streamerPort,
        serverProcessId,
        playerProcessId,
      } = data?.ports;

      return {
        playerProcessId: playerProcessId,
        serverProcessId: serverProcessId,
        httpsPort: httpsPort,
        streamerPort: streamerPort,
        httpPort: httpPort,
        success: true,
      };
    }

    if (data.statusCode === 204) {
      return { success: false, message: "No more slots are available please try later." };
    }

  } catch (error) {
    console.error("Error:", error.message);
    return { success: false, message: "No more slots are available please try later." };
  }
};

export const closePixelStreamInstance = async (
  tempID
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}:8000/remove-user/userId=${tempID}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    // console.log("Manual close");

    return { result: "closed" };
  } catch (error) {
    console.error("Error:", error.message);
    return { result: "closed" };
  }
};

export const getCustomDataApiHandler = async (custom_id) => {
  const payload = {
    stella_custom_id: custom_id,
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CUSTOM_ID_CHECKER_URL}/new-uwc/dashboard/custom-stella/read`,
      {
        method: "POST",
        headers: {
          "X-API-Key": process.env.REACT_APP_X_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const responseData = await response.json();
    console.log(responseData);
    return responseData;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const checkDisclaimerStatus = async (userId) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${userId}/tnc_status`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Server did not return JSON");
    }

    const data = await response.json();
    return data?.accepted_terms;
  } catch (error) {
    console.error("Error:", error.message);
    return { message: "some error" };
  }
};

export const acceptDisclaimer = async (userId) => {
  try {
    const response = await fetch(
      `https://integrations.uwc.world/users/${userId}/accept_tnc`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Server returned ${response.status} ${response.statusText}`
      );
    }

    const contentType = response.headers.get("content-type");
    if (!contentType || !contentType.includes("application/json")) {
      throw new Error("Server did not return JSON");
    }

    const data = await response.json();
    return data?.message;
  } catch (error) {
    console.error("Error:", error.message);
    return { message: "some error" };
  }
};

export const fetchSTTWhisper = async (data) => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://s2t-v2.uwc.world/transcribe-v2",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic YWRtaW46WDlyJDN2ITJ3UXorOHBG",
      },
      data,
    };

    const resp = await axios.request(config);

    return resp?.data?.transcription;
  } catch (error) {
    console.error("Whisper API error", error);
  }
};
