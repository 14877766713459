import { AttachFile, Attachment } from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import imageCompression from "browser-image-compression";
import { signInAnonymously } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, storage } from "../firebaseConfig";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
function DocumentAttachment({ handleTranscript }) {
  const fileInputRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("ERROR", errorMessage);
      });
  }, []);

  const getTranscript = async (ocr_url) => {
    const url = process.env.REACT_APP_LAMA_OCR_URL;
    const authHeader = "Basic " + process.env.REACT_APP_OCR_PASSWORD;

    const data = {
      image_url: ocr_url,
      lmm_prompt: "extract the details",
      llm_prompt:
        "these are the contents of a medical file uploaded by a patient. Can you provide me the comprehensive medical description.",
      max_tokens: 4000,
      temperature: 0,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: authHeader,
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      // console.log("Response:", responseData);
      handleTranscript(responseData?.response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    // Do something with the selected file
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
    ];
    // console.log("Selected file:", file);

    try {
      if (allowedTypes.includes(file?.type)) {
        enqueueSnackbar("File uploaded successfully", {
          variant: "success",
        });
        const dirName = dayjs().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (error) => {
            console.error("Upload error:", error);
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);
              //   console.log("url", url);
              getTranscript(url);
            } catch (error) {
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
        enqueueSnackbar("Incorrect file format", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };

  return (
    <>
      <IconButton className={``} onClick={() => fileInputRef.current.click()}>
        <div className="lg:min-h-[50px] bg-[#73589B] lg:min-w-[50px] min-w-[36px] min-h-[36px]  flex  items-center justify-center rounded-full">
          <AttachFile sx={{ color: "white" }} />
        </div>
      </IconButton>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileInputChange}
        accept="image/png,image/jpeg,image/jpg,application/pdf"
      />
    </>
  );
}

export default DocumentAttachment;
